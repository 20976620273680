<template>
  <div class="partner">
    <div class="w content ty400" ref="transform1">
      <div class="title-img">
        <img src="@/assets/images/partner-image/page01.png" alt="">
      </div>
      <div class="item-box">
        <template v-for="(item, index) in partnersPicList" :key="index">
          <div class="row row2">
            <div class="item" v-for="(item2, index2) in item" :key="index2" :class="{'none': !item2}">
              <div class="img-box">
                <img :src="item2" alt="">
              </div>
              <div class="triangle2"></div>
              <div class="triangle3"></div>
              <div class="triangle4"></div>
              <div class="triangle5"></div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  onUnmounted, reactive, toRefs,
} from 'vue';
import apis from '@/request/apis';
import { itemGsap } from '../index/js/usePage';
import img1 from '@/assets/images/partner-image/page03.png';

export default {
  setup() {
    const data = reactive({
      partnersPicList: [],
      transform1: null,
    });

    const splitArr = (arr) => {
      let count = 6;
      const partnersPicList = [];
      for (let i = 0; i < arr.length; i += count) {
        const tempArr = [];
        for (let j = 0; j < count; j++) {
          tempArr.push(arr[j + i]);
        }
        count = count === 6 ? 7 : 6;
        partnersPicList.push(tempArr);
      }
      data.partnersPicList = partnersPicList;
      itemGsap(data.transform1, {
        y: 0,
        opacity: 1,
        duration: 1,
        ease: 'none',
      });
    };

    const detail = async () => {
      const res = await apis.receptionEnterSpikeDepth.getDetail();
      const { partnersPic } = res;
      if (partnersPic) {
        const arr = partnersPic.split(',');
        arr.push(img1);
        splitArr(arr);
      }
    };
    detail();

    onUnmounted(() => {
      const app = document.querySelector('#app');
      app.scrollTop = 0;
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.partner {
  height: 103.125vw;
  width: 100vw;
  background-image: url('~@/assets/images/partner-image/page02.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50% 50%;
  overflow: hidden;
  .title-img {
    width: 31.615vw;
    height: 6.146vw;
    margin-top: 10.104vw;
    margin-bottom: 5.208vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .item-box {
    .row {
      display: flex;
      justify-content: center;
      .item {
        width: 9.375vw;
        height: 5.729vw;
        background-color: #fff;
        position: relative;
        margin-right: 1.042vw;
        transition: all 0.5s;
        &:hover {
          transform: scale(1.1);
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .img-box {
        width: 100%;
        height: 100%;
        padding: 0.521vw;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .triangle2{
        width: 0;
        height: 0;
        border-bottom: 2.604vw solid #fff;
        border-left: 4.76vw solid transparent;
        position: absolute;
        top: -2.5vw;
        left: 0;
      }
      .triangle3{
        width: 0;
        height: 0;
        border-bottom: 2.604vw solid #fff;
        border-right: 4.76vw solid transparent;
        position: absolute;
        right: 0;
        top: -2.5vw;
      }

      .triangle4{
        width: 0;
        height: 0;
        border-top: 2.604vw solid #fff;
        border-left: 4.76vw solid transparent;
        position: absolute;
        bottom: -2.5vw;
        left: 0;
      }
      .triangle5{
        width: 0;
        height: 0;
        border-top: 2.604vw solid #fff;
        border-right: 4.76vw solid transparent;
        position: absolute;
        right: 0;
        bottom: -2.5vw;
      }
    }
    .row2 {
      margin-top: 3.333vw;
    }
    .none {
      opacity: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .partner {
    height: auto;
    width: 100vw;
    background-image: url('~@/assets/images/partner-image/page02.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50% 50%;
    overflow: hidden;
    .title-img {
      width: 50vw;
      height: auto;
      margin-top: 10.104vw;
      margin-left: 4vw;
      margin-bottom: 0.208vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item-box {
      padding: 2.667vw 1.333vw;
      .row {
        display: flex;
        justify-content: flex-start;
        overflow-x: scroll;
        .item {
          width: 30vw;
          flex-shrink:0;
          height: 15.729vw;
          background-color: #fff;
          position: relative;
          margin-right: 1.042vw;
          &:last-child {
            margin-right: 0;
          }
        }
        .img-box {
          width: 100%;
          height: 100%;
          padding: 0.521vw;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .triangle2{
          width: 0;
          height: 0;
          border-bottom: 2.604vw solid #fff;
          border-left: 4.76vw solid transparent;
          position: absolute;
          top: -2.5vw;
          left: 0;
          display: none;
        }
        .triangle3{
          width: 0;
          height: 0;
          border-bottom: 2.604vw solid #fff;
          border-right: 4.76vw solid transparent;
          position: absolute;
          right: 0;
          top: -2.5vw;
          display: none;
        }

        .triangle4{
          width: 0;
          height: 0;
          border-top: 2.604vw solid #fff;
          border-left: 4.76vw solid transparent;
          position: absolute;
          bottom: -2.5vw;
          left: 0;
          display: none;
        }
        .triangle5{
          width: 0;
          height: 0;
          border-top: 2.604vw solid #fff;
          border-right: 4.76vw solid transparent;
          position: absolute;
          right: 0;
          bottom: -2.5vw;
          display: none;
        }
      }
      .row2 {
        margin-top: 3.333vw;
        &:nth-child(even) {
          padding-left: 6.667vw;
        }
      }
      .none {
        opacity: 0;
      }
    }
  }
}
</style>
